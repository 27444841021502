import { useIntl } from 'react-intl';

export const useMessages = () => {
  const intl = useIntl();

  return {
    orderDetailsFormSuccess: intl.formatMessage({
      defaultMessage: 'Order information successfully saved',
      id: 'order_details_form.success',
    }),
  };
};
