import { getReadability, readableFontColor } from '../../../../utils/colors';

export default theme => ({
  changeLocationButton: {
    color: theme.consumer.menuItemCart.summary.link.color,
    textTransform: 'capitalize',
  },
  compactDateAndTimeSelectContainer: {
    '& > div.MuiGrid-item': {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2.5),
    },
    marginBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  currentLocationFont: {
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  description: {
    color: getReadability('#FFF', theme.palette.secondary.main) < 1.25 ? `${readableFontColor(theme.palette.secondary.main)}` : theme.palette.secondary.main,
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  firstFulfillmentTypeToggleButton: {
    borderRadius: `${theme.spacing(0.5)}px 0px 0px ${theme.spacing(0.5)}px`,
  },
  fulfillmentTypeToggleButton: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    flex: 1,
    height: '36px',
    padding: theme.spacing(0.5),
  },
  locationAddress: {
    fontSize: '1.25em',
  },
  minorMarginBottom: {
    marginBottom: theme.spacing(1.25),
  },
  radioGroupFormControl: {
    '& .Mui-checked': {
      color: theme.consumer.general.infoMessage.color,
    },
  },
  secondaryDescription: {
    color: getReadability('#FFF', theme.palette.secondary.main) < 1.25 ? `${readableFontColor(theme.palette.secondary.main)}` : theme.palette.secondary.main,
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0, 2, 0),
  },
  secondFulfillmentTypeToggleButton: {
    borderRadius: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px`,
  },
  selectedFulfillmentTypeToggleButton: {
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
  smallSubmitButton: {
    marginTop: theme.spacing(0),
  },
  submitButton: {
    borderRadius: '4px',
    minHeight: theme.spacing(6),
  },
  submitButtonContainer: {
    bottom: 0,
    left: 0,
    margin: theme.spacing(3, 0, 0, 0),
    padding: theme.spacing(1, 4),
    position: 'fixed',
    right: 0,
    zIndex: 10,
  },
  submitGroupButtonContainer: {
    '&.MuiGrid-item': {
      marginTop: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  submitGroupForSmallVariant: {
    borderRadius: '3px',
    marginTop: 0,
  },
  subtitleMessage: {
    color: '#595959',
    fontSize: '0.875em',
  },
  zeroMarginBottom: {
    marginBottom: 0,
  },
  zeroMarginTop: {
    marginTop: 0,
  },
});
