// needs to be updated with app/services/restaurant/location_service.rb
export default {
  AE: 'United Arab Emirates',
  AR: 'Argentina',
  AU: 'Australia',
  BS: 'The Bahamas',
  CA: 'Canada',
  DO: 'Dominican Republic',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  GU: 'Guam',
  HN: 'Honduras',
  HR: 'Croatia',
  IE: 'Ireland',
  IN: 'India',
  JE: 'Jersey',
  JP: 'Japan',
  KR: 'South Korea',
  MX: 'Mexico',
  NG: 'Nigeria',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  US: 'United States',
  VG: 'British Virgin Islands',
  VI: 'U.S. Virgin Islands',
} as const;
