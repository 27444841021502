import React, { useEffect, useState } from 'react';
import { Box } from '@popmenu/common-ui';
import { useFormContext } from 'react-form';

import TextField from '../../../../admin/shared/forms/TextField';

const UkLocationDeliveryInput = ({
  classes,
  deliveryAddress,
  deliveryAddressError,
  setDeliveryAddress,
  setDeliveryAddressExtra,
  setMenuItemCartDeliveryAddressError,
  t,
}) => {
  const { setFieldValue } = useFormContext();
  const setShowEdit = value => setFieldValue('showDeliveryEdit', value);
  const splitDeliveryAddress = deliveryAddressError && deliveryAddress?.split('|');
  const [streetNumber, setStreetNumber] = useState(splitDeliveryAddress?.[0] || null);
  const [addressLine1, setAddressLine1] = useState(splitDeliveryAddress?.[1] || null);
  const [addressLine2, setAddressLine2] = useState(splitDeliveryAddress?.length === 5 ? splitDeliveryAddress[2] : null);
  const [townCity, setTownCity] = useState(splitDeliveryAddress ? splitDeliveryAddress?.length === 5 ? splitDeliveryAddress[3] : splitDeliveryAddress[2] : null);
  const [postalCode, setPostalCode] = useState(splitDeliveryAddress ? splitDeliveryAddress?.length === 5 ? splitDeliveryAddress[4] : splitDeliveryAddress[3] : null);

  useEffect(() => {
    if (streetNumber && addressLine1 && townCity && postalCode) {
      if (deliveryAddressError) {
        setDeliveryAddress(`${streetNumber}|${addressLine1}|${addressLine2 ? `${addressLine2}|` : ''}${townCity}|${postalCode}`);
      } else {
        setDeliveryAddress(`${streetNumber} ${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${townCity} ${postalCode}`);
        // We set deliveryAddressExtra to update menuItemCartDeliveryAddressExtra,
        // which is "Building/Apt/Unit (e.g. 100A)" field for US locations.
        setDeliveryAddressExtra(streetNumber);
      }
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLine1, addressLine2, deliveryAddressError, postalCode, setDeliveryAddress, streetNumber, townCity]);

  return (
    <Box>
      <TextField
        id="street-number-input"
        name="streetNumber"
        placeholder={t('consumer.ordering.delivery_address_input.custom.street_number')}
        onChange={(e) => {
          setStreetNumber(e.target.value);
          setMenuItemCartDeliveryAddressError(null);
        }}
        value={streetNumber}
      />
      <TextField
        id="address-1-input"
        className={classes.customAddressInput}
        name="addressLine1"
        placeholder={t('consumer.ordering.delivery_address_input.custom.address1')}
        onChange={(e) => {
          setAddressLine1(e.target.value);
          setMenuItemCartDeliveryAddressError(null);
        }}
        value={addressLine1}
      />
      <TextField
        id="address-2-input"
        className={classes.customAddressInput}
        name="addressLine2"
        placeholder={t('consumer.ordering.delivery_address_input.custom.address2')}
        onChange={(e) => {
          setAddressLine2(e.target.value);
          setMenuItemCartDeliveryAddressError(null);
        }}
        value={addressLine2}
      />
      <TextField
        id="town-city-input"
        className={classes.customAddressInput}
        name="townCity"
        placeholder={t('consumer.ordering.delivery_address_input.custom.town_city')}
        onChange={(e) => {
          setTownCity(e.target.value);
          setMenuItemCartDeliveryAddressError(null);
        }}
        value={townCity}
      />
      <TextField
        id="postal-code-input"
        className={classes.customAddressInput}
        name="postalCode"
        placeholder={t('consumer.ordering.delivery_address_input.custom.postal_code')}
        onChange={(e) => {
          setPostalCode(e.target.value);
          setMenuItemCartDeliveryAddressError(null);
        }}
        value={postalCode}
      />
    </Box>
  );
};

export default UkLocationDeliveryInput;
