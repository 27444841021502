import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { TogoBag, Truck } from '@popmenu/web-icons';
import { useQuery } from '~/lazy_apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@popmenu/common-ui/';

import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '../../../../utils/withStyles';
import styles from './styles';

import BasicModal from '../../../../admin/shared/BasicModal';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { withWindowSizeContext } from '../../../../shared/WindowSizeProvider';
import OrderDetailsForm from '../OrderDetailsForm';
import { getFulfillmentTypeOptions, getOrderingLocationsFromRestaurant } from '../MenuItemCartHelpers';
import Loading from '../../../../shared/Loading';
import { useIntl } from '../../../../utils/withIntl';
import { closeEditMenuItemCartModal } from '../../../../shared/DishActions';
import locationsQuery from '../../../../libs/gql/queries/locations/restaurantWithLocationsQuery.gql';
import sessionWithMenuItemCartQuery from '../../../../libs/gql/queries/menu_item_carts/sessionWithMenuItemCartQuery.gql';
import { setMenuItemCartFulfillmentType } from '../../../../shared/MenuItemCartActions';
import { setOrderEditModalHasShown } from '../../../../shared/ConsumerActions';
import orderingEventBySlug from '../../../../libs/gql/queries/ordering_events/orderingEventBySlugQuery.gql';

const useStyles = makeStyles(styles);

const OrderDetailsModal = ({ restaurant, isMobile }) => {
  const t = useIntl();
  const { theme } = restaurant;
  const classes = useStyles();
  const dispatch = useDispatch();

  const editing = useSelector(state => state.dishes.editing);
  const isPreview = useSelector(state => state.consumer.isPreview);
  const locationId = useSelector(state => state.menuItemCart.menuItemCartLocationId);
  const reduxCartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const fulfillmentType = useSelector(state => state.menuItemCart.menuItemCartFulfillmentType);
  const showMenuItemModal = useSelector(state => state.dishes.showMenuItemModal);
  const showEditMenuItemCartModal = useSelector(state => state.dishes.showEditMenuItemCartModal);
  const editMenuItemCartModalOnCloseCallback = useSelector(state => state.dishes.editMenuItemCartModalOnCloseCallback);

  const routeMatch = useRouteMatch({ path: '/orders/:orderingEventSlug/order', strict: true });
  const { data: orderingEventData } = useQuery(orderingEventBySlug, { skip: !routeMatch?.params.orderingEventSlug, variables: { restaurantId: restaurant.id, slug: routeMatch?.params.orderingEventSlug } });
  const orderingEvent = orderingEventData?.orderingEventBySlug;

  const { data: menuItemCartData } = useQuery(sessionWithMenuItemCartQuery, { variables: { cartRestaurantId: restaurant.id, cartType: reduxCartType, locationId, orderingEventId: orderingEvent?.id } });
  const menuItemCart = menuItemCartData?.currentSession?.pendingMenuItemCart;

  const isModernLayout = theme.dishLayout === 'modern_dish_layout';
  const showModal = isModernLayout ? (showEditMenuItemCartModal && !showMenuItemModal) : showEditMenuItemCartModal; // this ensures that the two modals do not open on top of each other

  const { data: locationsData, loading: locationsLoading } = useQuery(
    locationsQuery,
    {
      skip: !showEditMenuItemCartModal,
      variables: {
        restaurantId: restaurant.id,
      },
    },
  );

  const currentLocation = locationsData?.restaurant?.locations?.find(({ id }) => id === locationId);

  // NOTE: The following two effect accomplish roughly the same thing, but the second effect is specifically for mobile. They should probably be consolidated.
  // set cart's fulfillmentType when missing
  useEffect(() => {
    if (!fulfillmentType && menuItemCart) {
      dispatch(setMenuItemCartFulfillmentType(menuItemCart.fulfillmentType));
    }
  }, [menuItemCart, fulfillmentType, dispatch]);

  // set cart's fulfillmentType when missing (mobile layout only)
  useEffect(() => {
    if (isMobile && showEditMenuItemCartModal && !fulfillmentType && menuItemCart && !locationsLoading && locationsData?.restaurant?.locations) {
      const { location, cartType } = menuItemCart;
      const { asapLocations, scheduleLocations } = getOrderingLocationsFromRestaurant(restaurant, locationsData.restaurant.locations, cartType);

      const fulfillmentTypeOptions = getFulfillmentTypeOptions(asapLocations, scheduleLocations, cartType, undefined, location.id, orderingEvent);
      const cartFulfillmentType = fulfillmentTypeOptions.find(option => option.value === menuItemCart.fulfillmentType)?.value;
      dispatch(setMenuItemCartFulfillmentType(cartFulfillmentType || fulfillmentTypeOptions[0]?.value));
    }
  }, [dispatch, fulfillmentType, locationsData?.restaurant?.locations, locationsLoading, restaurant, showEditMenuItemCartModal, isMobile, menuItemCart, orderingEvent]);

  if (!showEditMenuItemCartModal || isPreview) {
    return null;
  }

  if (locationsLoading || !locationsData?.restaurant?.locations || !menuItemCart) {
    return <Loading size="lg" />;
  }

  const onClose = () => {
    dispatch(closeEditMenuItemCartModal());
    dispatch(setOrderEditModalHasShown(true));
    if (editMenuItemCartModalOnCloseCallback) {
      editMenuItemCartModalOnCloseCallback();
    }
  };

  const { location, cartType } = menuItemCart;
  const isUkLocation = (menuItemCart.uiMenuItemCart.isUkBased || restaurant.isOnlyUkBased);
  const { asapLocations, scheduleLocations } = getOrderingLocationsFromRestaurant(restaurant, locationsData.restaurant.locations, cartType);
  const filteredAsapLocations = asapLocations.filter(l => l.id === location.id);
  const filteredScheduleLocations = scheduleLocations.filter(l => l.id === location.id);

  const orderTypePickupOptionCustomLabel = location.orderTypePickupOptionCustomLabel || t('consumer.ordering.pickup');
  const fulfillmentTypeOptions = getFulfillmentTypeOptions(filteredAsapLocations, filteredScheduleLocations, cartType, orderTypePickupOptionCustomLabel, null, orderingEvent);

  let icon = null;
  if (fulfillmentType === 'delivery_fulfillment_type') {
    icon = Truck;
  } else if (fulfillmentType === 'pickup_fulfillment_type') {
    icon = TogoBag;
  }

  return (
    <BasicModal
      closeModal={onClose}
      PaperProps={{ 'data-cy': 'order_details_modal_paper' }}
      size="md"
      show={showModal}
      title={t('consumer.ordering.details_modal_title')}
      transitionDuration={800}
      transitionDelay={editing ? 0 : 800}
      icon={icon}
      fullScreen={isUkLocation && isMobile}
      centerTitle
      mobileSmall={!isUkLocation && isMobile}
    >
      <Box marginX={3} marginBottom={isUkLocation && isMobile ? 11 : undefined}>
        {fulfillmentTypeOptions.length > 1 && (
          <Box marginBottom={1} marginTop={4} data-cy="fulfillment_types_buttons">
            {menuItemCart.containsAlcohol && menuItemCart.location.deliveryType === 'doordash_delivery_type' && (
              <Box display="flex" justifyContent="center" marginBottom={1}>
                <Typography variant="subtitle2">
                  <FormattedMessage id="order_details.delivery_prohibited" defaultMessage="Your cart contains alcoholic items. This location does not currently allow delivery of alcoholic items" />
                </Typography>
              </Box>
            )}
            <ToggleButtonGroup
              block
              color="primary"
              exclusive
              field="fulfillmentType"
              options={fulfillmentTypeOptions}
              value={fulfillmentType}
              onChange={(e, nextValue) => nextValue && dispatch(setMenuItemCartFulfillmentType(nextValue))}
              style={{ width: '100%' }}
            >
              {fulfillmentTypeOptions.map((option, index) => (
                <ToggleButton
                  key={option.value}
                  value={option.value}
                  disabled={option.value === 'delivery_fulfillment_type' &&
                    menuItemCart.containsAlcohol &&
                    menuItemCart.location.deliveryType === 'doordash_delivery_type'}
                  variant="secondary"
                  color="secondary"
                  className={classNames([
                    classes.fulfillmentTypeToggleButton,
                    option.value === fulfillmentType ?
                      classes.selectedFulfillmentTypeToggleButton : null,
                    index === 0 ?
                      classes.firstFulfillmentTypeToggleButton :
                      classes.secondFulfillmentTypeToggleButton,
                  ])}
                >
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
        {currentLocation && (
          <Box justifyContent="center" marginBottom={1} marginTop={1} width={158}>
            <Typography className={classes.currentLocationFont}>
              {currentLocation.name}
            </Typography>
            <Typography className={classes.currentLocationFont}>
              {currentLocation.fullAddress}
            </Typography>
          </Box>
        )}
        <OrderDetailsForm
          allLocations={locationsData.restaurant.locations}
          asapLocations={filteredAsapLocations}
          classes={classes}
          restaurant={restaurant}
          onSubmitted={onClose}
          scheduleLocations={filteredScheduleLocations}
          menuItemCart={menuItemCart}
          isMobile={isMobile}
          orderingEvent={orderingEvent}
        />
      </Box>
    </BasicModal>
  );
};

OrderDetailsModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default compose(
  withRestaurant,
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(OrderDetailsModal);
