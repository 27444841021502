import React, { useRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@popmenu/common-ui';
import { compose, mapProps } from '@shakacode/recompose';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withIntl } from '../../../../utils/withIntl';

import MapGeocoder from '../../../../shared/MapGeocoder';

import styles from './styles';
import { makeStyles } from '../../../../utils/withStyles';
import TextField from '../../../../admin/shared/forms/TextField';
import {
  setMenuItemCartDeliveryAddress,
  setMenuItemCartDeliveryAddressError,
  setMenuItemCartDeliveryLocations, setMenuItemCartDeliveryPendingLocations,
  setMenuItemCartDeliveryPendingVerifiedAddress,
  setMenuItemCartDeliveryVerifiedAddress,
} from '../../../../shared/MenuItemCartActions';
import { closeEditMenuItemCartModal } from '../../../../shared/DishActions';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { withWindowSizeContext } from '../../../../shared/WindowSizeProvider';
import { isPickupAvailableForAsapOrSchedule } from '../MenuItemCartHelpers';
import UkLocationDeliveryInput from './UkLocationDeliveryInput';

const useStyles = makeStyles(styles);

const MenuItemCartDeliveryInput = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deliveryAddressError = useSelector(state => state.menuItemCart.menuItemCartDeliveryAddressError);
  const deliveryVerifiedAddress = useSelector(state => state.menuItemCart.menuItemCartDeliveryVerifiedAddress);
  const deliveryFullAddress = props.menuItemCart ? props.menuItemCart.deliveryFullAddress : `${deliveryVerifiedAddress.streetAddress}${(props.deliveryAddressExtra ? ` #${props.deliveryAddressExtra}` : '')}, ${deliveryVerifiedAddress.city}, ${deliveryVerifiedAddress.state} ${deliveryVerifiedAddress.postalCode}`;
  const deliveryAddress = useSelector(state => state.menuItemCart.menuItemCartDeliveryAddress);
  const deliveryAddressWithoutNumber = props.menuItemCart?.deliveryAddressWithoutNumber ? props.menuItemCart.deliveryAddressWithoutNumber.replace('\n', ' ') : '';

  const deliveryPendingLocations = useSelector(state => state.menuItemCart.menuItemCartDeliveryPendingLocations);
  const deliveryPendingVerifiedAddress = useSelector(state => state.menuItemCart.menuItemCartDeliveryPendingVerifiedAddress);

  const [showEdit, setShowEdit] = useState(deliveryAddress || (props.isUkBased && !deliveryAddressError));

  const isUkLocation = props.menuItemCart?.uiMenuItemCart?.isUkBased || props.restaurant.isOnlyUkBased;
  const invalidAddressRef = useRef();
  const scrollToTarget = (targetRef) => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (isUkLocation && deliveryAddressError) {
      scrollToTarget(invalidAddressRef);
    }
  }, [deliveryAddressError, deliveryFullAddress, isUkLocation, props.isEditFlow]);

  return (
    <div className={classNames([props.classes.secondaryContainer, props.compactStyle && classes.compactAddressContainer])}>
      {(!props.compactStyle || !props.isMobile) && (
        <Typography className={props.classes.secondaryDescription}>
          <FormattedMessage id="consumer.ordering.delivery_address" defaultMessage="Delivery Address" />
        </Typography>
      )}
      {(showEdit && deliveryFullAddress) && (
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <Box item className={props.compactStyle ? classes.compactAddressWrapper : null}>
            {deliveryFullAddress}
          </Box>
          <Box item>
            <Button
              className={props.classes.changeLocationButton}
              onClick={() => setShowEdit(false)}
              size="small"
              variant="text"
              data-cy={'edit_delivery_address'}
            >
              <FormattedMessage id="consumer.ordering.edit" defaultMessage="Edit" />
            </Button>
          </Box>
        </Box>
      )}
      {(!showEdit || !deliveryFullAddress) && (
        <React.Fragment>
          {/* TODO: fix suggested UK addresses */}
          {
            isUkLocation ? (
              <UkLocationDeliveryInput
                classes={classes}
                deliveryAddress={deliveryAddress}
                deliveryAddressError={deliveryAddressError}
                setDeliveryAddress={props.setDeliveryAddress}
                setDeliveryAddressExtra={props.setDeliveryAddressExtra}
                setMenuItemCartDeliveryAddressError={error => dispatch(setMenuItemCartDeliveryAddressError(error))}
                setShowEdit={setShowEdit}
                t={props.t}
              />
            ) : (
              <MapGeocoder
                id="delivery-address-input"
                hideSearchIcon
                onClear={() => {
                  dispatch(setMenuItemCartDeliveryAddressError(null));
                  dispatch(setMenuItemCartDeliveryAddress(null));
                }}
                onChange={(e) => {
                  props.setDeliveryAddress(e.query.join(' '));
                  dispatch(setMenuItemCartDeliveryAddressError(null));
                }}
                onResultCallback={e => props.setDeliveryAddress(e.result.place_name)}
                placeholder={props.t('consumer.ordering.delivery_address_input')}
                value={deliveryAddressWithoutNumber || deliveryAddress}
                suggestionsLimit={props.suggestionsLimit}
              />
            )
          }
          <br />
          {!isUkLocation && (
            <TextField
              characterLimit={100}
              className={classes.addressInput}
              name="addressExtra"
              placeholder={props.t('consumer.ordering.delivery_address_extra_placeholder')}
              onChange={e => props.setDeliveryAddressExtra(e.target.value)}
              value={props.deliveryAddressExtra}
              InputProps={{ classes: { input: classes.addressInputV3 } }}
            />
          )}
        </React.Fragment>
      )}
      <Box ref={invalidAddressRef}>
        {deliveryAddressError && !props.isEditFlow && deliveryAddressError === 'invalid' && (
          <Typography className={classes.addressError}>
            <FormattedMessage
              id="consumer.ordering.delivery_address_invalid"
              defaultMessage="Sorry, <b>{name}</b> doesn't deliver to that address."
              values={{
                b: chunks => <b>{chunks}</b>,
                name: props.selectedLocation ? props.selectedLocation.name : props.restaurant.name,
              }}
            />
          </Typography>
        )}
        {deliveryAddressError && deliveryAddressError === 'door_dash_network_error' && (
          <Typography className={classes.addressError}>
            <FormattedMessage
              id="consumer.ordering.delivery_address_network_error"
              defaultMessage="Sorry, there was a problem validating your address. Please try again."
            />
          </Typography>
        )}
        {deliveryAddressError && props.isEditFlow && deliveryAddressError === 'invalid' &&
          (isPickupAvailableForAsapOrSchedule(props.selectedLocation, props.menuItemCart.cartType) ? (
            <Typography className={classes.addressError}>
              <FormattedMessage
                id="consumer.ordering.delivery_address_invalid_2"
                defaultMessage="Sorry, <b>{name}</b> doesn't deliver to that address. Enter a different address, switch to pickup, or <a>start over</a>."
                values={{
                  a: chunks => (
                    <a
                      href={'#choose-location'}
                      className={classes.startOverLink}
                      onClick={() => {
                        dispatch(closeEditMenuItemCartModal());
                        dispatch(setMenuItemCartDeliveryAddressError(null));
                        dispatch(setMenuItemCartDeliveryAddress(null));
                      }}
                    >
                      {chunks}
                    </a>
                  ),
                  b: chunks => <b>{chunks}</b>,
                  name: props.selectedLocation ? props.selectedLocation.name : props.restaurant.name,
                }}
              />
            </Typography>
          ) : (
            <Typography className={classes.addressError}>
              <FormattedMessage
                id="consumer.ordering.delivery_address_invalid_2_v3"
                defaultMessage="Sorry, your address is out of our delivery range."
              />
            </Typography>
          ))}
        {deliveryAddressError && props.isEditFlow && deliveryAddressError === 'invalid_with_other_valid_locations' && (
          <Typography className={classes.addressError}>
            <FormattedMessage
              id="consumer.ordering.delivery_address_invalid_3"
              defaultMessage="Sorry, <b>{name}</b> doesn't deliver to that address. However, another location does deliver to that address, <c>click here</c> to use this new delivery location."
              values={{
                a: chunks => (
                  <a
                    href={'#getting-started'}
                    className={classes.startOverLink}
                    onClick={() => {
                      dispatch(closeEditMenuItemCartModal());
                      dispatch(setMenuItemCartDeliveryAddressError(null));
                      dispatch(setMenuItemCartDeliveryAddress(null));
                    }}
                  >
                    {chunks}
                  </a>
                ),
                b: chunks => <b>{chunks}</b>,
                c: chunks => (
                  <a
                    href={'#choose-location'}
                    className={classes.startOverLink}
                    onClick={() => {
                      dispatch(closeEditMenuItemCartModal());
                      dispatch(setMenuItemCartDeliveryAddressError(null));
                      dispatch(setMenuItemCartDeliveryVerifiedAddress(deliveryPendingVerifiedAddress));
                      dispatch(setMenuItemCartDeliveryLocations(deliveryPendingLocations));
                      dispatch(setMenuItemCartDeliveryPendingVerifiedAddress(null));
                      dispatch(setMenuItemCartDeliveryPendingLocations(null));
                    }}
                  >
                    {chunks}
                  </a>
                ),
                name: props.selectedLocation ? props.selectedLocation.name : props.restaurant.name,
              }}
            />
          </Typography>
        )}
        {deliveryAddressError && deliveryAddressError === 'missing' && !deliveryFullAddress && (
          <Typography className={classes.addressError}>
            <FormattedMessage
              id="consumer.ordering.delivery_address_missing"
              defaultMessage="An address is required for delivery orders."
            />
          </Typography>
        )}
      </Box>
    </div>
  );
};

MenuItemCartDeliveryInput.defaultProps = {
  compactStyle: false,
  deliveryAddress: null,
  deliveryAddressExtra: null,
  isEditFlow: false,
  menuItemCart: null,
  selectedLocation: null,
  suggestionsLimit: undefined,
};

MenuItemCartDeliveryInput.propTypes = {
  classes: PropTypes.object.isRequired,
  compactStyle: PropTypes.bool,
  deliveryAddress: PropTypes.string,
  deliveryAddressExtra: PropTypes.string,
  isEditFlow: PropTypes.bool,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
  }),
  restaurant: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
  selectedLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setDeliveryAddress: PropTypes.func.isRequired,
  setDeliveryAddressExtra: PropTypes.func.isRequired,
  suggestionsLimit: PropTypes.number,
};

export default compose(
  withIntl,
  withRestaurant,
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(MenuItemCartDeliveryInput);
