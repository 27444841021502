import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@popmenu/common-ui';
import { useDispatch } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import Confirm from '../../../../admin/shared/Confirm';
import { preventEditMenuItemCartModalOnCloseCallback } from '../../../../shared/DishActions';
import { setOrderEditModalHasShown } from '../../../../shared/ConsumerActions';

const SeeAllLocations = ({ classes, onSubmit, loading, history, values }) => {
  const dispatch = useDispatch();
  return (
    <Confirm
      loading={loading}
      message={<FormattedMessage id="consumer.ordering.confirm_change_location_message" defaultMessage="Selecting a different location will clear out your current bag." />}
      onConfirm={() => {
        dispatch(preventEditMenuItemCartModalOnCloseCallback());
        dispatch(setOrderEditModalHasShown(true));
        history.push('#choose-location');
        onSubmit(values, true);
      }}
      title={<FormattedMessage id="consumer.ordering.change_location" defaultMessage="See All Locations" />}
    >
      {({ openModal }) => (
        <Button
          className={classes.changeLocationButton}
          onClick={openModal}
          size="small"
          variant="text"
        >
          <FormattedMessage id="consumer.ordering.change_location" defaultMessage="See All Locations" />
        </Button>
      )}
    </Confirm>
  );
};

SeeAllLocations.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default compose(withRouter)(SeeAllLocations);
