// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import loadable from '@loadable/component';
import { Box, CircularProgress } from '@material-ui/core';
import retry from '../../../../utils/retry';

const CartScheduler = loadable(() => retry(() => import('./CartScheduler')), {
  fallback: (
    <Box display="flex" justifyContent="center" alignItems="center" height={88}>
      <CircularProgress />
    </Box>
  ),
  resolveComponent: components => components.CartScheduler,
});

export default CartScheduler;
