import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';

import classNames from 'classnames';
import verifyAddressQuery from '../../../../libs/gql/queries/menu_item_carts/menuItemCartVerifyAddressQuery.gql';
import RadioGroup from '../../../../admin/shared/forms/RadioGroup';
import { canSwitchToDeliveryLocation, getFirstSelectableDate, getOrderTimeOptions } from '../MenuItemCartHelpers';
import BasicForm, { SubmitGroup } from '../../../../admin/shared/forms/BasicForm';
import updateMenuItemCartMutation from '../../../../libs/gql/mutations/menu_item_carts/updateMenuItemCartMutation.gql';
import { setMenuItemCartDeliveryAddress, setMenuItemCartDeliveryAddressError, setMenuItemCartDeliveryAddressExtra, setMenuItemCartDeliveryLocations, setMenuItemCartDeliveryPendingLocations, setMenuItemCartDeliveryPendingVerifiedAddress, setMenuItemCartDeliveryVerifiedAddress, setMenuItemCartFulfillmentType, setMenuItemCartIsScheduled, setMenuItemCartScheduledAt } from '../../../../shared/MenuItemCartActions';
import { useIntl } from '../../../../utils/withIntl';
import { useSnackbar } from '../../../../utils/withSnackbar';
import MenuItemCartDeliveryInput from '../MenuItemCartDeliveryInput';
import { closeEditMenuItemCartModal, setMenuId } from '../../../../shared/DishActions';
import SeeAllLocations from '../EditMenuItemCartHelpers/SeeAllLocations';
import CartScheduler from '../CartScheduler/CartScheduler.imports-loadable';
import { useMessages } from './useMessages';
import { AH } from '../../../shared/AccessibleHeading';

const OrderDetailsForm = ({ allLocations, asapLocations, isMobile, scheduleLocations, classes, restaurant, onSubmitted, menuItemCart, orderingEvent }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const t = useIntl();
  const { showSnackbar, showSnackbarError } = useSnackbar();
  const messages = useMessages();
  const cartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const editing = useSelector(state => state.dishes.editing);
  const fulfillmentType = useSelector(state => state.menuItemCart.menuItemCartFulfillmentType);
  const deliveryAddress = useSelector(state => state.menuItemCart.menuItemCartDeliveryAddress);
  const deliveryAddressExtra = useSelector(state => state.menuItemCart.menuItemCartDeliveryAddressExtra);
  const menuItemCartScheduledAt = useSelector(state => state.menuItemCart.menuItemCartScheduledAt);
  const isDelivery = fulfillmentType === 'delivery_fulfillment_type';
  const isPickup = fulfillmentType === 'pickup_fulfillment_type';
  const isStandardCart = cartType === 'default_cart_type';
  const isCateringCart = cartType === 'catering_cart_type';
  const isDeliveryTypeAndIsAddressEmpty =
    isDelivery &&
    !deliveryAddress?.trim() &&
    !menuItemCart?.deliveryFullAddress?.trim();

  const { location } = menuItemCart;
  const orderTimeLaterOptionCustomLabel = location?.orderTimeLaterOptionCustomLabel || 'Later';
  const orderTimeOptions = useMemo(() => getOrderTimeOptions(asapLocations, scheduleLocations, fulfillmentType, orderTimeLaterOptionCustomLabel, !!orderingEvent),
    [asapLocations, fulfillmentType, orderTimeLaterOptionCustomLabel, scheduleLocations, orderingEvent]);
  const filteredScheduleLocations = scheduleLocations
    .filter(l => (
      (isStandardCart && isPickup && l.scheduledOrderTimeSlots?.length > 0) ||
      (isStandardCart && isDelivery && l.scheduledDeliveryTimeSlots?.length > 0) ||
      (isCateringCart && isPickup && l.cateringOrderTimeSlots?.length > 0) ||
      (isCateringCart && isDelivery && l.cateringDeliveryTimeSlots?.length > 0)
    ));
  const filteredAsapLocations = asapLocations.filter(loc => (isDelivery && loc.isDeliveryAvailable) || (isPickup && loc.isOrderingAvailable));

  const [isScheduled, setIsScheduled] = useState(// if isEditing, get isScheduled status from menuItemCart
    (editing && menuItemCart?.isScheduled) ||
    // if no ASAP locations, must be a scheduled order. You would not be able to get this form to render if there were no schedule locations either
    filteredAsapLocations.length === 0 ||
    !!orderingEvent);

  const [updateMenuItemCart] = useMutation(updateMenuItemCartMutation);

  const submitForm = useCallback(async (isScheduledValue, scheduledAtTime, fulfillmentTypeValue, blockPropsSubmit, deliveryVerifiedAddress, distance) => {
    try {
      const {
        city: deliveryCity,
        state: deliveryState,
        postalCode: deliveryPostalCode,
        streetAddress: deliveryStreetAddress,
        formattedAddress: deliveryFormattedAddress,
      } = deliveryVerifiedAddress || {};

      await updateMenuItemCart({
        variables: {
          menuItemCartId: menuItemCart.id,
          menuItemCartInput: {
            deliveryAddressNumber: deliveryAddressExtra || undefined,
            deliveryCity,
            deliveryDistance: distance,
            deliveryFormattedAddress,
            deliveryPostalCode,
            deliveryState,
            deliveryStreetAddress,
            fulfillmentType: fulfillmentTypeValue,
            isScheduled: isScheduledValue,
            rawDeliveryAddress: deliveryAddress,
            scheduledAt: scheduledAtTime,
          },
          submitMenuItemCart: false,
        },
      });
      dispatch(setMenuItemCartIsScheduled(isScheduledValue));
      dispatch(setMenuItemCartFulfillmentType(fulfillmentTypeValue));
      if (isScheduledValue) {
        dispatch(setMenuItemCartScheduledAt(scheduledAtTime));
      }

      if (typeof onSubmitted === 'function' && !blockPropsSubmit) {
        onSubmitted();
      }
      // Reset the menuId to prevent it being erroneously used for date/time options for later updates
      dispatch(setMenuId(null));
      dispatch(closeEditMenuItemCartModal());
      setLoading(false);
      // Use hidden snackbar to add status message for screen readers
      showSnackbar(messages.orderDetailsFormSuccess, { className: 'sr-only' });
    } catch (err) {
      setLoading(false);
      showSnackbarError(err);
    }
  }, [deliveryAddress, deliveryAddressExtra, dispatch, menuItemCart.id, messages.orderDetailsFormSuccess, onSubmitted, showSnackbar, showSnackbarError, updateMenuItemCart]);

  const verifyAddress = useCallback((isScheduledValue, scheduledAtTime, fulfillmentTypeValue, blockPropsSubmit) => {
    const setError = (errorType) => {
      dispatch(setMenuItemCartDeliveryAddressError(errorType));
      setLoading(false);
    };

    if (deliveryAddress || menuItemCart?.deliveryFullAddress) {
      getApolloClient().then(client => client.query({
        fetchPolicy: 'network-only',
        query: verifyAddressQuery,
        variables: {
          address: deliveryAddress || menuItemCart?.deliveryFullAddress,
          menuItemCartId: menuItemCart?.id,
          restaurantId: restaurant.id,
          scheduledAt: isScheduledValue ? scheduledAtTime : null,
        },
      }).then(({ data }) => {
        const {
          city,
          postalCode,
          state,
          streetAddress,
          formattedAddress,
          locations,
          isValid,
        } = data?.menuItemCartVerifyAddress || {};

        if (isValid && locations.length >= 1) {
          const deliveryVerifiedAddress = {
            city,
            formattedAddress,
            postalCode,
            state,
            streetAddress,
          };

          const pendingLocations = locations.map(({ distance, id }) => ({ distance, id }));
          const filteredLocations = data.menuItemCartVerifyAddress.locations.filter(l => l.id === location.id);
          if (filteredLocations.length === 1) {
            dispatch(setMenuItemCartDeliveryVerifiedAddress(deliveryVerifiedAddress));
            dispatch(setMenuItemCartDeliveryLocations(pendingLocations));
            const [{ distance }] = filteredLocations;
            submitForm(
              isScheduledValue,
              scheduledAtTime,
              fulfillmentTypeValue,
              blockPropsSubmit,
              deliveryVerifiedAddress,
              distance,
            );
          } else if (data.menuItemCartVerifyAddress.locations.some(l => canSwitchToDeliveryLocation(l.id, isScheduledValue, allLocations))) {
            dispatch(setMenuItemCartDeliveryPendingVerifiedAddress(deliveryVerifiedAddress));
            dispatch(setMenuItemCartDeliveryPendingLocations(pendingLocations));
            setError('invalid_with_other_valid_locations');
          } else {
            setError('invalid');
          }
        } else {
          setError('invalid');
        }
      }).catch((err) => {
        const gqlError = err.graphQLErrors && err.graphQLErrors[0];
        if (gqlError && gqlError.message) {
          setError(gqlError.message);
        } else {
          setError('invalid');
        }
      }));
    } else {
      setError('missing');
    }
  }, [deliveryAddress, menuItemCart?.deliveryFullAddress, menuItemCart?.id, dispatch, restaurant.id, location.id, submitForm, allLocations]);

  const onSubmit = useCallback(async (values, blockPropsSubmit) => {
    setLoading(true);
    if (fulfillmentType === 'delivery_fulfillment_type') {
      verifyAddress(values.isScheduled, values.scheduledAtTime, fulfillmentType, blockPropsSubmit);
    } else {
      await submitForm(values.isScheduled, values.scheduledAtTime, fulfillmentType, blockPropsSubmit);
    }
  }, [submitForm, verifyAddress, fulfillmentType]);

  const scheduledAtDate = getFirstSelectableDate(editing && menuItemCart?.scheduledAt ? menuItemCart.scheduledAt : null, filteredScheduleLocations, fulfillmentType, cartType, menuItemCart);

  /*
  The starting time must be found in the list of time options,
  this way we avoid selecting a time that does not exist in the list.
  */

  const SaveButton = ({ isDisabled }) => (
    <SubmitGroup
      classes={classes}
      className={classNames([classes.submitGroupForSmallVariant])}
      color="primary"
      size="md"
      justify={isMobile ? 'center' : 'flex-end'}
      title={t(
        isDeliveryTypeAndIsAddressEmpty && isMobile ?
          'consumer.ordering.details_modal_submit_title_please_enter' :
          'consumer.ordering.details_modal_submit_title_save',
      )}
      block={isMobile}
      loading={loading}
      disabled={isDisabled}
      ButtonProps={{ 'data-cy': 'submit_button' }}
      submitGroupButtonContainerStyle={classes.submitGroupButtonContainer}
    />
  );
  return (
    <BasicForm
      defaultValues={{
        fulfillmentType,
        isScheduled,
        scheduledAtDate,
        scheduledAtTime: menuItemCartScheduledAt,
        showDeliveryEdit: !deliveryAddress,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const isSaveButtonDisabled = isDelivery &&
          !(deliveryAddress?.trim() && !values.showDeliveryEdit) &&
          !(menuItemCart?.deliveryFullAddress?.trim() && values.showDeliveryEdit && !(menuItemCart?.uiMenuItemCart?.isUkBased || restaurant.isOnlyUkBased));
        const waitingForScheduledTimeSelection = values.isScheduled && (!values.scheduledAtTime || values.scheduledAtTime === 'none');
        if (values.isScheduled !== isScheduled) setIsScheduled(values.isScheduled);
        return (
          <div data-cy="order_details_form">
            {editing && allLocations.length > 1 && (
              <SeeAllLocations
                classes={classes}
                onSubmit={onSubmit}
                loading={loading}
                values={values}
              />
            )}
            {(!isMobile) && (
              <AH typography className={classes.description} variant="h3">
                <FormattedMessage id="consumer.ordering.order_time" defaultMessage="Order Time" />
              </AH>
            )}
            {filteredAsapLocations.length < 1 && (
              <Typography className={classes.subtitleMessage}>
                <FormattedMessage id="consumer.ordering.asap_not_available" defaultMessage="*ASAP ordering is currently unavailable" />
              </Typography>
            )}
            {filteredScheduleLocations.length < 1 && (
              <Typography className={classes.subtitleMessage}>
                <FormattedMessage id="consumer.ordering.scheduled_not_available" defaultMessage="*Later ordering is currently unavailable" />
              </Typography>
            )}
            <RadioGroup
              formGroupProps={{ className: classes.minorMarginBottom }}
              aria-label="Order Time"
              formControlProps={{ className: classNames(classes.radioGroupFormControl, classes.zeroMarginBottom) }}
              classes={classes}
              field="isScheduled"
              options={orderTimeOptions}
              style={{ width: '100%' }}
            />
            {values.isScheduled && <CartScheduler />}
            {isDelivery && (
              <MenuItemCartDeliveryInput
                classes={classes}
                deliveryAddress={deliveryAddress}
                deliveryAddressExtra={deliveryAddressExtra}
                isEditFlow
                isUkBased={menuItemCart?.uiMenuItemCart?.isUkBased || restaurant.isOnlyUkBased}
                menuItemCart={menuItemCart}
                restaurant={restaurant}
                selectedLocation={location}
                setDeliveryAddress={newDeliveryAddress => dispatch(setMenuItemCartDeliveryAddress(newDeliveryAddress))}
                setDeliveryAddressExtra={newDeliveryAddressExtra => dispatch(setMenuItemCartDeliveryAddressExtra(newDeliveryAddressExtra))}
                suggestionsLimit={3}
                compactStyle
              />
            )}
            <SaveButton isDisabled={isSaveButtonDisabled || waitingForScheduledTimeSelection} />
          </div>
        );
      }}
    </BasicForm>
  );
};

OrderDetailsForm.propTypes = {
  allLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  asapLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onSubmitted: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  scheduleLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};

export default OrderDetailsForm;
