export default theme => ({
  addressError: {
    color: theme.consumer.menuItemCart.errorMessage.color,
    fontSize: 'small',
    marginTop: `${theme.spacing(1)}px`,
  },
  addressInput: {
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  addressInputV3: {
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  addressSuccess: {
    color: theme.consumer.menuItemCart.successMessage.color,
    fontSize: 'small',
    marginTop: `${theme.spacing(1)}px`,
  },
  addressWrapper: {
    marginTop: '4px',
  },
  compactAddressContainer: {
    marginBottom: theme.spacing(2.5),
  },
  compactAddressWrapper: {
    marginTop: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  customAddressInput: {
    marginTop: theme.spacing(1.5),
  },
  startOverLink: {
    color: theme.consumer.menuItemCart.errorMessage.color,
    textDecoration: 'underline',
  },
});
